.header-block-0 .header {
    background: transparent !important;
    
}

.header-block-0:not([class*="bg-"]) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


[class*="header-block"] .small {
    min-height: 160px;
}

[class*="header-block"] .medium {
    min-height: 320px;
}

[class*="header-block"] .large {
    min-height: 480px;
}

[class*="header-block"] .extra-large {
    min-height: 680px;
}

[class*="header-block"] .full {
    min-height: 100vh;
}

[class*="header-block"] .quoteTwo::before {
    left: -5%;
}

[class*="header-block"] .quoteTwo::after {
    right: -5%;
}

/*--------------Header-1 - design 2 - standard header styles--------------*/

@media (max-width: theme('screens.sm')) {
    .header-block-1 .section {
        min-height: 100% !important;
    }
}

/*--------------End Header-1 - design 2 - standard header styles--------------*/


/*--------------Overlap Header styles--------------*/
.header-block-3 .header.overlap.section > div:first-of-type {
    z-index: 1;
}

@media (max-width: theme('screens.sm')) {
    .header-block-3 .overlap-panel {
        @apply order-first;
    }
}


/* desktop styles: media right */
@media (min-width: theme('screens.sm')) {
    .header-block-3 .right .media {
        left: -20%;
        max-width: 120%;
    }
}



/* Mobile styles: media right */
@media (max-width: theme('screens.sm')) {
    .header-block-3 .right .overlap-panel {
        position: relative;
        margin-top: 0;
        right: calc(50vw - 8px);
        transform: translateX(50%) !important;

    }
}

@media (max-width: theme('screens.sm')) {
    .header-block-3 .right .media {
        position: relative;
        left: 50vw;
        transform: translateX(-50%) !important;
    }
}


/* desktop styles: media left */
@media (min-width: theme('screens.sm')) {
    .header-block-3 .left .media {
        right: -20%;
        max-width: 120%;
        left: 0;
    }
}

/* Mobile styles: media left */
@media (max-width: theme('screens.sm')) {
    .header-block-3 .left .overlap-panel {
        position: relative;
        margin-top: -20%;
        left: 50vw;
        transform: translateX(-50%) !important;
    }
}

@media (max-width: theme('screens.sm')) {
    .header-block-3 .left .media {
        position: relative;
        right: 50vw;
        transform: translateX(50%) !important;
    }
}
/*--------------End Overlap Header styles--------------*/


/*--------------Split Header styles--------------*/


/*.header-block-2 div:first-child {*/
/*    padding: var(--container-spacing);*/
/*}*/

@media (min-width: theme('screens.sm')) {
    .header-block-2 .section [class*="sm:mr-auto"] {
        padding-right: calc(var(--container-spacing));
    }

    .header-block-2 .section [class*="sm:ml-auto"] {
        padding-left: calc(var(--container-spacing));
    }
}

@media (max-width: theme('screens.sm')) {
    .header-block-2 .section {
        min-height: 100% !important;
    }
}

/*--------------End Split Header styles--------------*/
    
